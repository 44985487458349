import React from 'react'
import { graphql } from 'gatsby';

import Home from '../templates/home';

const HomePage = ({ data, location }) => {
  return <Home data={data} location={location} />
}

export default HomePage;

export const pageQuery = graphql`
  query {
    wordpressPage(
      wordpress_id: { eq: 29 }
    ) {
      id
      wordpress_id
      title
      slug
      content
      wpml_current_locale
      yoast_meta {
        name
        content
        property
      }
      acf {
        # Hero section
        hero {
          text
          block {
            content
            title
            cta {
              label
              relations {
                path
              }
            }
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        # Deliver section
        deliver {
          title
          functions {
            name
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          before_cta
          cta {
            label
            relations {
              path
            }
          }
          background {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1529) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        # Klantcase section
        klantcase: case {
          background {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1440) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          cta
          relations {
            slug
            path
            acf {
              quote {
                content
                quoted_person {
                  name
                  function
                }
              }
            }
          }
        }

        # About section
        about {
          title
          columns {
            title
            content
          }
          cta {
            label
            relations {
              path
            }
          }
        }


        # Blog - projecten section
        projecten {
          title
          cta
          cta_overview {
            label
            relations {
              path
            }
          }
          relations {
            title
            excerpt
            slug
            path
            acf {
              subtitle
              banner {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 1440) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
